import { turboRequest } from '../../../../helpers/turbo_helper'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.filter = this.filter.bind(this)
    this.servicesChanged = this.servicesChanged.bind(this)
    this.programsChanged = this.programsChanged.bind(this)
    this.fundingSourcesChanged = this.fundingSourcesChanged.bind(this)
    this.reloadExportOptions = this.reloadExportOptions.bind(this)

    addEventListener('input:change', this.servicesChanged)
    addEventListener('input:change', this.programsChanged)
    addEventListener('input:change', this.fundingSourcesChanged)
    addEventListener('turbo:before-frame-render', this.reloadExportOptions)
  }

  disconnect() {
    removeEventListener('input:change', this.servicesChanged)
    removeEventListener('turbo:before-frame-render', this.reloadExportOptions)
  }

  servicesChanged(event) {
    if (event.detail.name !== 'service_definition_ids') {
      return
    }
    this.filter()
  }

  programsChanged(event) {
    if (event.detail.name !== 'program_ids') {
      return
    }
    this.filter()
  }

  fundingSourcesChanged(event) {
    if (event.detail.name !== 'funding_source_ids') {
      return
    }
    this.filter()
  }

  filter() {
    this.element.requestSubmit()
  }

  reloadExportOptions() {
    turboRequest(`members/export_settings${window.location.search}`)
    turboRequest(`members/export_settings_options${window.location.search}`)
    turboRequest(`members/export_smart_list${window.location.search}`)
  }
}
